.signup-centered-box {
  width: 70%;
  /* background-color: #e7e5fd; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0% 0%;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  position: relative;
  z-index: 1;
  margin-top: 10px;
}
.upper-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
  /* margin-top: 80px; */
}
.signup-page {
  margin-top: 80px;
  overflow: auto;
}

.signup-or-login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Adjust width as needed */
  margin: 5px 0; /* Adjust margin as needed */
  padding: 3%;
}

.signup-or-login-container .line {
  flex-grow: 1;
  height: 1px;
  background-color: black;
  margin: 0 10px; /* Space between the line and the text */
}

.signup-or-login-container.or-text {
  padding: 0 10px; /* Adjust padding as needed */
  color: black; /* Adjust text color as needed */
}
.upper-section h3 {
  margin-bottom: 0px;
  color: #000;
  font-weight: 800;
  font-family: Mulish;
  font-size: 40px;
  text-align: center;
}
.upper-section p {
  color: #0d0715;
  font-family: raleway;
  font-size: 14px;
  margin: 0.5% 21% 1% 24%;
  text-align: center;
}

.signup-form .form-row {
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
  margin-right: 0px;
}

.signup-form .form-group {
  margin-bottom: 10px;
}

.signup-form .form-row .form-group {
  width: 48%;
}

.signup-form .form-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 1px;
  text-align: left;
  font-weight: 600;
}

.signup-form input[type="text"],
.signup-form input[type="email"],
.signup-form input[type="password"] {
  width: 100%;
  padding: 7px 10px;
  font-size: 14px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  box-sizing: border-box;
  height: 30px;
}

.signup-form input[type="text"]::placeholder,
.signup-form input[type="email"]::placeholder,
.signup-form input[type="password"]::placeholder {
  /* color: #aaa; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.signup-form .form-options label {
  font-size: 14px;
}

.signup-form .form-footer {
  text-align: center;
}

.signup-form button[type="submit"] {
  /* background-color: #000; */
  /* color: #ffffff; */
  /* border: none; */
  /* padding: 5px 0px; */
  /* width: 45%; */
  /* font-size: 18px; */
  /* font-weight: bold; */
  /* border-radius: 15px; */
  cursor: pointer;
  /* box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2); */
}

.signup-form .form-footer p {
  font-size: 14px;
  /* color: #000; */
  margin-top: 8px;
  font-weight: 500;
}

.signup-form .form-footer a {
  /* color: #5d54f1; */
  text-decoration: none;
  font-weight: 600;
}

.left-image {
  position: absolute;
  bottom: 0px;
  height: 40%;
  left: -15%;
}
.full-screen-border-box {
  border: 1px solid black;
  height: calc(100vh - 5.3rem);
  margin: 8px 8px 8px 8px;
  border-radius: 10px;
  margin-top: 80px;
}
.form-options {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.checkbox-container {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-custom {
  width: 20px;
  height: 15px;
  background-color: white;
  border: 2px solid #7d7d7d;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.checkbox-container input[type="checkbox"]:checked + .checkbox-custom::after {
  content: "";
  position: absolute;
  left: 2px;
  top: -4px;
  width: 8px;
  height: 14px;
  /* border: solid #5d54f1; */
  border: solid #18181b;
  border-width: 0 2px 2px 0;
  transform: rotate(35deg);
}

.checkbox-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-family: "Raleway";
  text-align: left;
}

.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 4px solid rgba(255, 255, 255, 0.2); /* Light grey border for the unloaded part */
  border-top: 4px solid gray; /* White for the loaded part */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mobile-upper-section {
  display: none;
}
.signup-main-container {
  display: flex;
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.login-left {
  width: 50%;
  /* background: linear-gradient(90deg, #5d54f1 0%, #36308b 100%); */
  border-radius: 10px;
}
.login-right {
  width: 50%;
  padding: 20px 20px 0px 10px;
}

.login-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.main-signup-text h3 {
  color: white;
  font-family: raleway;
  font-weight: 700;
  /* margin-top: 30px; */
  font-size: 35px;
  text-align: left;
  padding: 30px 10px 10px 20px;
}

.login-left p {
  color: white;
  font-family: raleway;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  padding: 0 20px;
}
.dialog-2 p {
  color: black;
  padding: 10px;
  font-family: raleway;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin: 0px;
}
.dialog-2 h6 {
  color: #5d54f1;
  font-weight: 600;
  font-family: raleway;
  width: 100%;
  text-align: left;
  padding: 10px;
}
:root {
  --main-light-color: #f9bbb1;
  --main-dark-color: #481b0b;
  --main-background-color: black;
}

.box2 {
  padding: 40px;
}

.left-point {
  width: 0;
  height: 0;
  border-left: 2vh solid transparent;
  border-right: 2vh solid transparent;
  border-top: 10vh solid var(--main-dark-color);
  position: absolute;
  top: 45%;
  left: -10%;
  transform: rotate(60deg);
}

.dialog-2 {
  /* height: 20%; */
  width: 40%;
  background-color: #ffffff;
  position: absolute;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.right-point {
  width: 0;
  height: 0;
  border-left: 2vh solid transparent;
  border-right: 2vh solid transparent;
  border-top: 10vh solid #ffffff;
  position: absolute;
  top: 98%;
  right: 6%;
  transform: rotate(0deg);
}
@media (max-width: 768px) {
  .signup-centered-box {
    width: 90%;
    height: auto;
    padding: 20px;
    margin-top: 25px;
  }
  .upper-section p {
    margin: 0px;
  }
  .login-left-image {
    position: relative;
    left: 0px;
  }
  .left-image {
    position: relative;
    left: 0px;
  }
  .checkbox-custom {
    width: 50px;
    height: 20px;
  }
  .signup-form .form-row {
    flex-direction: column;
  }
  .signup-form .form-row .form-group {
    width: 100%;
  }
  .upper-section {
    display: none;
  }
  .mobile-upper-section {
    display: block;
  }
  .mobile-upper-section p {
    /* font-family: raleway; */
    /* font-weight: 700; */
    color: #000;
    /* font-size: 16px; */
  }
  .signup-form .form-group label {
    font-weight: 700;
  }
  .signup-form input[type="text"],
  .signup-form input[type="email"],
  .signup-form input[type="password"] {
    /* background-color: #ffffff; */
    /* border-radius: 20px; */
  }
  .full-screen-border-box {
    border: none;
  }
  .login-left {
    display: none;
  }
  .login-right {
    width: 100%;
    padding: 0px;
  }
  .signup-form button[type="submit"] {
    /* width: 65%; */
  }
}
