/* .centered-box {
  width: 60%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
  background-color: #e7e5fd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
} */

.login-container h3 {
  font-family: Mulish;
  color: #040606;
  font-weight: bold;
}

.or-login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  margin: 20px 0; /* Adjust margin as needed */
}

.line {
  flex-grow: 1;
  height: 1px;
  background-color: black;
  margin: 0 10px; /* Space between the line and the text */
}

.or-text {
  padding: 0 10px; /* Adjust padding as needed */
  color: black; /* Adjust text color as needed */
}

.login-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
  font-family: raleway;
}

.login-form input[type="email"],
.login-form input[type="password"] {
  width: 100%;
  /* padding: 5px; */
  font-size: 14px;
  height: 35px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  box-sizing: border-box;
  /* border-radius: 15px; */
}

.login-form .form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0px 0px;
}
.whole-login {
  position: relative;
}
.login-back-vec {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  max-width: 100%;
  opacity: 0.4;
}
.whole-login {
  position: relative;
  padding-bottom: 70px;
  width: 100%;
  overflow: hidden;
  margin-top: 80px;
}

.login-form .form-options .forgot-password a {
  font-size: 14px;
  color: #5d54f1;
  text-decoration: none;
  margin-left: 5px;
}

.login-form .form-footer {
  text-align: center;
}

.login-form button[type="submit"] {
  /* background-color: #5D54F1; */
  /* color: #ffffff; */
  /* border: none; */
  /* padding: 5px 20px; */
  /* font-size: 16px; */
  border-radius: 10px;
  cursor: pointer;
  /* width: 55%; */
  /* height: 40px; */
  /* box-shadow: 0 9px 14px rgba(0, 0, 0, 0.2), 0 13px 30px rgba(0, 0, 0, 0.2); */
}

.login-form .form-group input {
  height: 35px;
}

.login-form .form-footer p {
  font-size: 14px;
  /* color: #000; */
  font-family: raleway;
}

.login-form .form-footer a {
  /* color: #5d54f1; */
  text-decoration: none;
}
.login-form input[type="email"]::placeholder,
.login-form input[type="password"]::placeholder {
  /* color: #6b6a6a; */
  font-family: raleway;
  font-style: normal;
  font-size: 14px;
  /* padding: 14px; */
  font-weight: 400;
}
.form-footer p {
  font-family: raleway;
}
.login-left-image {
  position: absolute;
  bottom: -10px;
  left: -138px;
}
.Login-upper-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
}

.Login-upper-section h3 {
  margin-bottom: 0px;
  color: #000;
  font-weight: 800;
  font-family: Mulish;
  font-size: 40px;
  margin-top: 15px;
}
.Login-upper-section p {
  color: #0d0715;
  font-family: raleway;
  /* font-size: 16px; */
  /* margin-top: 5px; */
  margin-bottom: 25px;
  font-weight: 500;
}
.form-options label {
  font-weight: normal;
}
.forgot-password span {
  font-family: raleway;
  font-size: 14px;
}

.login-checkbox-container {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.login-checkbox-container input[type="checkbox"] {
  display: none;
}

.login-checkbox-custom {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #7d7d7d;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.login-checkbox-container
  input[type="checkbox"]:checked
  + .login-checkbox-custom::after {
  content: "";
  position: absolute;
  left: 4px;
  top: -2px;
  width: 10px;
  height: 15px;
  border: solid #18181b;
  border-width: 0 2px 2px 0;
  transform: rotate(40deg);
}

.login-checkbox-text {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  font-family: "Raleway";
  text-align: left;
}
.mobile-upper-section {
  display: none;
}

@media (max-width: 768px) {
  .centered-box {
    width: 90%;
    height: auto;
    padding: 20px;
    margin-top: 25px;
  }

  .login-form .form-options {
    flex-direction: column;
  }
  .Login-upper-section p {
    padding: 20px;
  }
  .Login-upper-section {
    display: none;
  }
  .or-login-container {
    width: 100%;
  }
  .login-form .form-footer p {
    font-weight: 600;
  }
  .login-form label {
    font-weight: 600 !important;
  }
  .login-form .form-options .forgot-password a {
    font-weight: 600;
  }
}
